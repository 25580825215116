@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-bold mb-14;
    line-height: theme('lineHeight.leading120');
  }
  h2 {
    @apply text-2xl font-bold mb-12;
    line-height: theme('lineHeight.leading120');
  }
  h3 {
    @apply text-xl font-bold;
    line-height: theme('lineHeight.leading140');
  }
  h4 {
    @apply text-xl font-bold;
    line-height: theme('lineHeight.leading140');
  }
  .subheadline {
    @apply font-semibold text-lg;
    line-height: theme('lineHeight.leading120');
  }
  html {
    @apply overflow-hidden;
  }
  body {
    @apply text-base;
    @apply text-text-primary;
    line-height: theme('lineHeight.leading150');
  }
  b,
  strong {
    @apply font-semibold;
  }
  a {
    @apply font-semibold;
    @apply text-primary-600;
  }

  *:focus {
    @apply touchable:outline-none;
  }
}

@layer components {
  .label {
    @apply text-sm text-gray-500;
    line-height: theme('lineHeight.leading140');
  }

  .lg-button {
    @apply py-3.5 px-4 text-sm;
  }

  .modal-title {
    @apply text-xl font-bold;
    line-height: theme('lineHeight.leading140');
  }

  .detail {
    @apply text-sm;
    line-height: theme('lineHeight.leading140');
  }
  .caption {
    @apply text-xs;
    line-height: theme('lineHeight.leading130');
  }
  .warning {
    @apply bg-orange-50 px-6 py-4 rounded-lg text-warning text-base border border-orange-100 w-fit min-w-[300px] text-center m-6;
  }
  .alert {
    @apply bg-red-50 px-6 py-4 rounded-lg text-alert text-base border border-red-100 w-fit min-w-[300px] text-center m-6;
  }
  .notification {
    @apply bg-primary-50 px-6 py-4 rounded-lg text-notification text-base border border-primary-100 w-fit min-w-[300px] text-center m-6;
  }

  .success {
    @apply bg-green-50 px-6 py-4 rounded-lg text-text-primary text-base border border-primary-100 w-fit min-w-[300px] text-center m-6;
  }

  /* auth0 login widget styles */
  #container .auth0-lock .auth0-lock-center {
    padding: 0;
  }
  #container .auth0-lock .auth0-lock-header {
    display: none;
  }
  #container .auth0-lock .auth0-lock-widget {
    width: 420px;
  }
  #container .auth0-lock .auth0-lock-submit {
    border-radius: 4px;
  }

  /* stepper styles */
  .stepper {
    @apply w-full py-4 flex justify-between items-center relative select-none;
  }
  .step-item {
    @apply relative flex flex-col items-center flex-grow;
  }
  .step-item:not(:first-child):before {
    @apply content-[''] bg-gray-300 absolute w-full h-[2px] right-2/4 top-3 -translate-y-2/4;
  }
  .step-enabled:not(:first-child):before {
    @apply bg-gray-500 h-[2px];
  }
  .step-active:not(:first-child):before {
    @apply bg-primary-600 h-[3px];
  }
}

@layer utilities {
  .transform-center {
    @apply transform -translate-x-1/2 -translate-y-1/2;
  }
}
