@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf');
  font-weight: 'regular';
  font-display: block;
}
@font-face {
  font-family: ('OpenSans');
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: 'bold';
  font-display: block;
}
@font-face {
  font-family: ('OpenSans');
  src: url('../fonts/OpenSans-SemiBold.ttf');
  font-weight: 'semi bold';
  font-display: block;
}
